import { BasicsClass } from '@/common/BasicsClass';

export class SummerRewardRecord extends BasicsClass {

    public apiUrls: any = new Map([
        ['list', {
            url: '/admin/summer/activityRewardRecordList',
            name: 'list',
            label: '中奖记录列表'
        }],
        ['export', {
            url: '/admin/summer/exportRewardRecordList',
            name: 'export',
            label: '导出'
        }],

    ])
    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {

        params = {
            ...(params || {})
        }

        const apiUrls = this.apiUrls

        const url = apiUrls.get('list').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    public exportList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('export').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

}
